<!--业扩效率信息-->
<template>
    <widget-framework v-if="dataLoaded" title="业扩效率信息">
        <template #content>
            <div style="display: flex; flex-direction: column;height: 100%">
                <div style="height: 45%; display: flex;flex-direction: row" >
                    <div style="width: 40%">
                        <v-chart :options="optAverages" :autoresize="true" style="width: 100%; height: 100%"></v-chart>
                    </div>
                    <div style="width: 60%">
                        <dv-scroll-board :config="config" style="height: 100%; width: 100%" ref="scrollBoard"/>
                    </div>
                </div>
                <div style="height: 55%">
                    <v-chart :options="optDetails" :autoresize="true" style="height:100%;width:100%"></v-chart>
                </div>
            </div>
        </template>
    </widget-framework>
    <dv-loading v-else></dv-loading>
</template>

<script>
import WidgetFramework from '@/components/widgets/WidgetFramework';
import {
    filledCircle,
    headerBGC,
    oddRowBGC,
    evenRowBGC,
    lightGrey,
    tooltip,
    white, whiteOpacity,
    yellow, cyan, grey, purple, blue, green,
} from '@/components/widgets/constant';
import EchartBase from '@/components/widgets/EchartBase';
import WidgetBase from '@/components/widgets/WidgetBase';
import numeral from 'numeral';
import dayjs from 'dayjs';

export default {
    name: 'BusinessApplyEfficiency',
    components: {
        WidgetFramework
    },
    mixins: [WidgetBase, EchartBase],
    data() {
        return {
            dataLoaded: false,
            optAverages: {
                textStyle: {
                    color: lightGrey
                },
                tooltip: {
                    trigger: 'item',
                    position: this.setTooltipPosition,
                    backgroundColor: tooltip.backgroundColor,
                    borderColor: tooltip.borderColor,
                    borderWidth: tooltip.borderWidth,
                    extraCssText: tooltip.extraCssText
                },
                dataset: [],
                series: [
                    {
                        name: '平均工时',
                        type: 'pie',
                        radius: [0, '60%'],
                        hoverAnimation: false,
                        itemStyle: {
                            color: filledCircle
                        },
                        label: {
                            show: true,
                            position: 'center',
                            formatter: (params) => {
                                const {value} = params.data;
                                return `{a|${value}}\n{b|平均工时}`;
                            },
                            rich: {
                                a: {
                                    color: yellow,
                                    fontSize: 20,
                                    height: 30,
                                    fontWeight: 'bold'
                                },
                                b: {
                                    color: white,
                                    fontSize: 10
                                }
                            }
                        },
                        labelLine: {
                            show: false,
                        },
                        datasetIndex: 0,
                        encode: {
                            value: 'value'
                        },
                        tooltip: {
                            formatter: (params) => {
                                const {value} = params.data;
                                return `平均工时：${value}`;
                            }
                        }
                    },
                    {
                        name: '用户数量',
                        type: 'pie',
                        radius: ['50%', '65%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'inner',
                            formatter: (params) => {
                                const {value} = params.data;
                                return `${value}`;
                            },
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        datasetIndex: 1,
                        encode: {
                            value: 'value'
                        },
                        tooltip: {
                            formatter: (params) => {
                                const {name, value} = params.data;
                                return `${name}：${value}`;
                            }
                        }
                    },
                    {
                        type: 'pie',
                        radius: ['80%', '85%'],
                        legendHoverLink: false,
                        hoverAnimation: false,
                        itemStyle: {
                            color: whiteOpacity
                        },
                        label: {
                            show: false,
                        },
                        labelLine: {
                            show: false,
                        },
                        data: [1],
                        markPoint: {
                            symbol: 'none'
                        },
                        tooltip: {
                            show: false,
                        }
                    },
                    {
                        type: 'pie',
                        radius: ['90%', '100%'],
                        legendHoverLink: false,
                        hoverAnimation: false,
                        itemStyle: {
                            color: whiteOpacity
                        },
                        label: {
                            show: false,
                        },
                        labelLine: {
                            show: false,
                        },
                        data: [1],
                        markPoint: {
                            symbol: 'none'
                        },
                        tooltip: {
                            show: false,
                        }
                    }
                ]
            },
            config: {
                header: ['', '阶段', '耗时', '耗时占比'],
                rowNum: 4,
                headerBGC,
                oddRowBGC,
                evenRowBGC,
                data: [],
                index: false,
                columnWidth: [40, 100, 90, 90],
                align: ['center'],
                carousel: 'single'
            },

            optDetails: {
                textStyle: {
                    color: lightGrey
                },
                tooltip: {
                    trigger: 'axis',
                    position: this.setTooltipPosition,
                    axisPointer: {
                        type: 'cross',
                        shadowStyle: {
                            color: cyan,
                            opacity: 0.2
                        }
                    },
                    backgroundColor: tooltip.backgroundColor,
                    borderColor: tooltip.borderColor,
                    borderWidth: tooltip.borderWidth,
                    extraCssText: tooltip.extraCssText,
                    formatter: (params) => {
                        const {businessNumber, applyPhaseHours, reconnaissancePhaseHours, planPhaseHours, completionPhaseHours} = params[0].data;
                        return `${businessNumber}<br/>
                                    申请阶段：${applyPhaseHours}小时<br/>
                                    勘测阶段：${reconnaissancePhaseHours}小时<br/>
                                    方案阶段：${planPhaseHours}小时<br/>
                                    验收阶段：${completionPhaseHours}小时`;
                    }

                },
                legend: {
                    data: ['申请阶段', '勘测阶段', '方案阶段', '验收阶段'],
                    top: 'bottom',
                    textStyle: {
                        color: cyan,
                        fontSize: 14
                    }
                },
                grid: {
                    top: '50',
                    left: '15',
                    right: '0',
                    bottom: '20',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        axisPointer: {
                            type: 'shadow'
                        },
                        axisLine: {
                            lineStyle: {
                                color: grey
                            }
                        },
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '单位：小时',
                        axisLine: {
                            lineStyle: {
                                color: grey
                            }
                        },
                        splitLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        }
                    }
                ],
                series: [
                    {
                        name: '申请阶段',
                        type: 'bar',
                        itemStyle: {
                            normal: {
                                color: cyan
                            }
                        },
                        encode: {
                            x: 'businessNumber',
                            y: 'applyPhaseHours'
                        }
                    },
                    {
                        name: '勘测阶段',
                        type: 'bar',
                        itemStyle: {
                            normal: {
                                color: green
                            }
                        },
                        encode: {
                            x: 'businessNumber',
                            y: 'reconnaissancePhaseHours'
                        }
                    },
                    {
                        name: '方案阶段',
                        type: 'bar',
                        itemStyle: {
                            normal: {
                                color: purple
                            }
                        },
                        encode: {
                            x: 'businessNumber',
                            y: 'planPhaseHours'
                        }
                    },
                    {
                        name: '验收阶段',
                        type: 'bar',
                        itemStyle: {
                            normal: {
                                color: blue
                            }
                        },
                        encode: {
                            x: 'businessNumber',
                            y: 'completionPhaseHours'
                        }
                    }
                ],
                dataset: {
                    source: []
                }
            }
        };
    },
    mounted() {
        this.$client.getBusinessApplyEfficiency().then((ret) => {
            const {applyPhaseAverageHours, reconnaissancePhaseAverageHours, planPhaseAverageHours, completionPhaseAverageHours, items} = ret.data;
            let totalTimeAverage = (applyPhaseAverageHours || 0) + (reconnaissancePhaseAverageHours || 0)
                + (planPhaseAverageHours || 0) + (completionPhaseAverageHours || 0);
            const list = [];
            list.push([
                `<span style="width:10px;height: 10px;border-radius: 5px;background-color:${cyan};display: inline-block"></span>`,
                '申请阶段',
                applyPhaseAverageHours,
                totalTimeAverage === 0 ? '~' : numeral(applyPhaseAverageHours / totalTimeAverage).format('0.00%')
            ]);
            list.push([
                `<span style="width:10px;height: 10px;border-radius: 5px;background-color:${green};display: inline-block"></span>`,
                '勘测阶段',
                reconnaissancePhaseAverageHours,
                totalTimeAverage === 0 ? '~' : numeral(reconnaissancePhaseAverageHours / totalTimeAverage).format('0.00%')
            ]);
            list.push([
                `<span style="width:10px;height: 10px;border-radius: 5px;background-color:${purple};display: inline-block"></span>`,
                '方案阶段',
                planPhaseAverageHours,
                totalTimeAverage === 0 ? '~' : numeral(planPhaseAverageHours / totalTimeAverage).format('0.00%')
            ]);
            list.push([
                `<span style="width:10px;height: 10px;border-radius: 5px;background-color:${blue};display: inline-block"></span>`,
                '验收阶段',
                completionPhaseAverageHours,
                totalTimeAverage === 0 ? '~' : numeral(completionPhaseAverageHours / totalTimeAverage).format('0.00%')
            ]);
            this.config.data = list;

            const dataset1 = [];
            dataset1.push({name: '申请阶段', value: applyPhaseAverageHours, itemStyle: {color: cyan}});
            dataset1.push({name: '勘测阶段', value: reconnaissancePhaseAverageHours, itemStyle: {color: green}});
            dataset1.push({name: '方案阶段', value: planPhaseAverageHours, itemStyle: {color: purple}});
            dataset1.push({name: '验收阶段', value: completionPhaseAverageHours, itemStyle: {color: blue}});

            const dataset = [];
            dataset.push({source: [{name: '平均工时', value: totalTimeAverage}]});
            dataset.push({source: dataset1});
            this.optAverages.dataset = dataset;

            this.optDetails.dataset.source = items || [];
            this.dataLoaded = true;
        });
    }
};
</script>

<style scoped>

</style>
